
import Phaser from 'phaser';
import Game from './game.js';

const config = {
  type: Phaser.AUTO,
  with: 640,
  height: 480,
  parent: 'game',
  physics: {
    default: 'arcade',
    arcade: {
      gravity: {
        y: 200
      },
    }
  },
  scene: [Game]
};

export default new Phaser.Game(config);