import Phaser from 'phaser';
import bg1 from '../assets/background_layer_1.png';
import bg2 from '../assets/background_layer_2.png';
import bg3 from '../assets/background_layer_3.png';
import bg4 from '../assets/background_layer_4.png';
import audioBg from '../assets/bg-audio.m4a';
import audioJump from '../assets/jump.mp3';
import playerRun from '../assets/Gothic-hero-Files/PNG/gothic-hero-run.png';
import playerJump from '../assets/Gothic-hero-Files/PNG/gothic-hero-jump.png';
import shop from '../assets/shop_anim.png';
import bad1 from '../assets/wolf-runing-cycle/sprites/wolf-runing-cycle-skin.png';
import prize from '../assets/prize.mp3';
import kill from '../assets/orange/end/burning_end_1.png';
import dead from '../assets/dead.mp3';

export default class Game extends Phaser.Scene {
  constructor() {
    super({ key: 'Game'});
  }

  init() {
    this.bg1Speed = 0.45;
    this.bg2Speed = 0.90;
    this.bg3Speed = 1.80;
    this.bg4Speed = 2.60;
    this.bad1Speed = 10;
    this.shopSpeed = 4; 
    this.hasJump = false;
    this.maxMeters = 0;
    this.currentMeters = 0;
    this.maxPoints = 0;
    this.currentPoints = 0;
    this.statusBad1 = false;
    this.statusBad2 = false;
    this.gameOver = false;
    this.level = 0;
    this.lastFramerate = 0;
  }

  preload() {
    this.load.image('bg1', bg1);
    this.load.image('bg2', bg2);
    this.load.image('bg3', bg3);
    this.load.image('bg4', bg4);
    this.load.spritesheet('playerRun', playerRun, { frameWidth: 66, frameHeight: 47, endFrame: 11 });
    this.load.spritesheet('playerJump', playerJump, { frameWidth: 60, frameHeight: 87, endFrame: 4 });
    this.load.spritesheet('shop', shop, { frameWidth: 118, frameHeight: 200, endFrame: 5 });
    this.load.spritesheet('bad1', bad1, { frameWidth: 57, frameHeight: 35, endFrame: 2 });
    this.load.spritesheet('kill', kill, { frameWidth: 25, frameHeight: 45, endFrame: 3 });
    this.load.audio('audioBg', audioBg);
    this.load.audio('audioJump', audioJump);
    this.load.audio('prize', prize);
    this.load.audio('dead', dead);
  }

  create() {
    this.animations();
    this.sprites();
    this.startAnimations();
    this.actionJump.visible = false;
    this.kill.visible = false;
    this.event();
    this.audio();
    this.inpudaData();
    this.text();
  }
  update() {
    if (!this.gameOver) {
      if (Phaser.Input.Keyboard.JustDown(this.spacebar)) {
        this.evtJump();
      }
      this.generateNewSprites();
      if (this.hasJump) {
        if (this.bad1.x < this.actionRun.x + 100 && !this.statusBad1) {
          this.audioPrize.play();
          this.statusBad1 = true;
          this.currentPoints += 10;
          this.textPoints.setText(this.currentPoints);
        }
        if (this.bad2.x < this.actionRun.x + 100 && !this.statusBad2) {
          this.audioPrize.play();
          this.statusBad2 = true;
          this.currentPoints += 10;
          this.textPoints.setText(this.currentPoints);
        }
      } else {
        if (!this.gameOver) {
          if (this.bad1.x < this.actionRun.x + 55 && this.bad1.x > this.actionRun.x - 10 || this.bad2.x < this.actionRun.x + 55 && this.bad2.x > this.actionRun.x - 10) {
            this.actionRun.visible && this.dead.play();
            this.actionRun.visible = false;
            this.actionJump.visible = false;
            this.gameOver = true;
            this.kill.visible = true;
            this.audioBg.stop();
            this.kill.play('killAnimation');
            }
        } 
      }
      this.spriteOffset();
    }
  }

  generateNewSprites() {
    if (this.actionShop.x < -300) {
      this.actionShop.x = this.random(4800, 1200);
    }
    if (this.bad1.x < - 150) {
      this.bad1.setTint(this.hsv[Phaser.Math.Between(0, 359)].color);
      this.bad1.x = this.random(400, 1200);
      this.statusBad1 = false;
    }
    if (this.bad2.x < - 150) {
      this.bad2.setTint(this.hsv[Phaser.Math.Between(0, 359)].color);
      this.bad2.x = this.random(400, 1900);
      this.statusBad2 = false;
    }
  }

  spriteOffset() {
    this.level = this.level > 18 ? 18 : this.level;
    this.bg1.tilePositionX += this.bg1Speed + this.level;
    this.bg2.tilePositionX += this.bg2Speed + this.level;
    this.bg3.tilePositionX += this.bg3Speed + this.level;
    this.bg4.tilePositionX += this.bg4Speed + this.level;
    this.actionShop.x -= this.shopSpeed + this.level * 2.5;
    this.bad1.x -= (this.bad1Speed * 1.16) + this.level;
    this.bad2.x -= (this.bad1Speed * 1.16) + this.level;
    this.bad1?.anims && this.bad1.anims.msPerFrame > 28 && (this.bad1.anims.msPerFrame -= this.level / 300);
    this.bad2?.anims && this.bad2.anims.msPerFrame > 28 && (this.bad2.anims.msPerFrame -= this.level / 300);
    this.textMeters.setText(`${this.currentMeters} m`);
    this.currentMeters += 1;
    this.lastFramerate = this.actionRun.anims.msPerFrame;
    this.actionRun?.anims && this.actionRun.anims.msPerFrame > 28 && (this.actionRun.anims.msPerFrame -= this.level / 300);
    this.actionJum?.anims && this.actionJum.anims.msPerFrame > 28 && (this.actionJum.anims.msPerFrame -= this.level / 300);
    this.currentMeters % 10000 === 0 && (this.level += 0.1);
  }

  random(min, max) {
    return Math.round((Math.random() * min) + max)
  }

  configAnimation(key, spriteName, start, end, frameRate, repeat) {
    return {
      key,
      frames: this.anims.generateFrameNumbers(spriteName, { start, end }),
      frameRate,
      repeat
    };
  }

  jump() {
    this.actionRun.visible = false;
    this.actionJump.visible = true;
    this.actionJump.play('jumpAnimation')
  }

  evtJump() {
    if (!this.gameOver) {
      !this.hasJump && this.audioJmp.play();
      !this.hasJump && this.jump();
      this.hasJump = true;
    }
  }

  animations() {
    this.anims.create(this.configAnimation('shopAnimation', 'shop', 0, 5, 10, -1));
    this.anims.create(this.configAnimation('runAnimation', 'playerRun', 0, 11, 10, -1));
    this.anims.create(this.configAnimation('jumpAnimation', 'playerJump', 0, 4, 16, 0));
    this.anims.create(this.configAnimation('bad1Animation', 'bad1', 0, 4, 9, -1));
    this.anims.create(this.configAnimation('killAnimation', 'kill', 0, 3, 7, 0));
  }

  sprites() {
    this.bg1 = this.add.tileSprite(430, 240, 440, 180, 'bg1').setScale(2.7);
    this.bg2 = this.add.tileSprite(430, 240, 440, 180, 'bg2').setScale(2.7);
    this.actionShop = this.add.sprite(1200, 245, 'shop').setScale(2.7);
    this.bg3 = this.add.tileSprite(430, 240, 440, 180, 'bg3').setScale(2.7);
    this.bg4 = this.add.tileSprite(430, 240, 440, 180, 'bg4').setScale(2.7);
    this.bad1 = this.add.sprite(1600, 386, 'bad1').setScale(2).setFlipX(true);
    this.bad2 = this.add.sprite(1900, 386, 'bad1').setScale(2).setFlipX(true);
    this.actionRun = this.add.sprite(100, 355, 'playerRun').setScale(2.7);
    this.actionJump = this.add.sprite(100, 320, 'playerJump').setScale(2.7);
    this.kill = this.add.sprite(120, 291, 'kill').setScale(8);
  }

  startAnimations() {
    this.actionShop.play('shopAnimation');
    this.actionRun.play('runAnimation');
    this.bad1.play('bad1Animation');
    this.bad2.play('bad1Animation');
  }
  
  audio() {
    this.audioBg = this.sound.add('audioBg', { volume: 0.6, loop: true });
    this.audioJmp = this.sound.add('audioJump', { volume: 1.2 });
    this.audioPrize = this.sound.add('prize', { volume: 1.2 });
    this.dead = this.sound.add('dead', { volume: 1.2 });
    this.audioBg.play();
  }
  
  event() {
    this.actionJump.on('animationcomplete', () => {
      this.actionRun.play('runAnimation');
      this.actionJump.visible = false;
      this.actionRun.visible = true;
      this.hasJump = false;
      this.audioJmp.stop()
      this.actionRun.anims.msPerFrame = this.lastFramerate;
      this.actionJump.anims.msPerFrame = this.lastFramerate;
    });
    this.kill.on('animationcomplete', () => {
      this.kill.visible = false;
      this.actionRun.visible = true;
      this.dead.stop();
      this.audioBg.stop();
      this.scene.restart();
    });
  }

  inpudaData() {
    this.spacebar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
    this.input.on('pointerdown', function () {
      this.evtJump();
    }, this);
  }

  text() {
    this.textMeters = this.add.text(this.scale.width - 10, 15, 10, `0 m`, { font: '15px Arial', fill: '#ffffff', align: 'right' }).setOrigin(1, 0.5);
    this.textPoints = this.add.text(8, 15, '0', { font: '15px Arial', fill: '#ffffff', align: 'right' }).setOrigin(0, 0.5);
    this.hsv = Phaser.Display.Color.HSVColorWheel();
  }
}